$(() => {
  
  const $root = $(':root');
  $root.on(cs.Snippet.EventIdPostReplace, ($event: cs.SnippetEventPostReplace) => {
    let target = $event.replacedTarget
    if (!target && $event.originalEvent) {
      const oEvent = ($event.originalEvent as any as cs.SnippetEventPostReplace | null)
      if (oEvent?.replacedTarget) {
        target = oEvent.replacedTarget;
      }
    }
    if (target) {
      eh.Global.init($(target), true);
    }
  });
  eh.Global.init($root);
  
});

namespace eh {
  
  export class Global {
    
    static init($base: JQuery<HTMLElement>, isSnippetRequest = false): void {

      eh.RestoreListScrollPosition.init($base);
      eh.InfiniteScroll.init($base, isSnippetRequest);
      eh.ExpandableList.init($base);
      eh.FilteredListCtrl.init($base);
      eh.HybridDatePicker.init($base);
      eh.Forms.init($base);
      eh.Accordion.init($base);
      eh.AccordionCtrl.init($base);
      eh.AliyunCaptcha.init($base, isSnippetRequest);
      eh.CountryOverview.init($base);
      eh.EventOverview.init($base);
      eh.Highlights.init($base);
      eh.SliderSmallOnly.init($base);
      eh.JobTicker.init($base);
      eh.MyDocumentsLoader.init($base, isSnippetRequest);
      eh.ProductComparison.init($base, isSnippetRequest);
      eh.ProductGuideIframe.init($base);
      //eh.ProductGuideLoader.init($base, isSnippetRequest);
      eh.SearchFilters.init($base, isSnippetRequest);
      eh.SearchResult.init($base, isSnippetRequest);
      eh.SearchResultsDla.init($base, isSnippetRequest);
      eh.StoryCards.init($base);
      eh.Tabs.init($base);
      eh.BreadcrumbController.init($base);
      eh.TooltipController.init($base);
      eh.QuickLinksController.init($base);
      eh.SignupOneAccount.init($base);
      eh.PreviewHelper.init($base, isSnippetRequest);
    }
  }

}


// Types of globally available extensions

// module: flowplayer
interface Flowplayer {
  isFullscreen: boolean;
  paused: boolean;
  resume(): void;
  pause(): void;
  fullscreen(): void;
  pause(): void;
}

// module: flowplayer
interface JQuery {
  flowplayer: any;
}

// module: jquery-form
interface JQuery {
  ajaxSubmit: (opts: {[key: string]: any}) => void;
  formSerialize: () => string;
}

// module: jquery-highlight
interface JQuery {
  highlight(term:string, options:{ [key: string]: string }):void;
}

// module: jquery-touchswipe
interface JQuery {
  swipe(obj: any): void;
}

// module: jquery-ui-datepicker
interface JQuery {
  datepicker: (opts: { [key: string]: any }) => void;
}

// module: jquery-ui-datepicker
interface JQueryStatic {
  datepicker: {
    _hideDatepicker(): void;
  };
}

// module: select2
declare module S2 {
  interface Utils {
    Decorate(classA: object, classB: object): object;
  }
}

// module: tealium
interface Window {
  utag: {
    link: () => void,
    view: () => void,
    gdpr: {
      showConsentPreferences: () => void
    }
  };
  utag_data: { [key: string]: string };
  _elqQ: { 
    push: ([]) => void;
  };
  GetElqCustomerGUID: () => any;
}

// module: picturefill
interface Window {
  picturefill: (opts: {[key: string]: any}) => void;
}

// module: browserDetect
interface Window {
  browserDetect(): any;
}

// module: aliyun-captcha
interface Window {
  noCaptcha: {
    new (opts: {[key: string]: any}): any;
  };
}

// public api nebp
interface Window {
  ehApi: eh.EhExternalApi
}
